@font-face {
    font-family: 'ManropeBold';
    src: url('../assets/fonts/Manrope-Bold.ttf');
}

@font-face {
    font-family: 'ManropeBody1';
    src: url('../assets/fonts/Manrope-ExtraLight.ttf');
}

@font-face {
    font-family: 'ManropeBody2';
    src: url('../assets/fonts/Manrope-Light.ttf');
}

@font-face {
    font-family: 'ManropeMedium';
    src: url('../assets/fonts/Manrope-Medium.ttf');
}

@font-face {
    font-family: 'ManropeRegular';
    src: url('../assets/fonts/Manrope-Regular.ttf');
}

